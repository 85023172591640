import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "badge badge-success"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.status === 1 || _ctx.status === '1')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Ativo"))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "Inativo"))
  ]))
}